import * as React from "react"
import { SVGProps } from "react"

// Styles
import colors from "../styles/colors"

const ZigZagLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={1214}
    height={174}
    viewBox="0 0 1214 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1285.85 108.235C1169.93 144.07 1053.76 173.915 966.5 173.915C879.182 173.915 773.69 140.476 669.898 107.273L666.342 106.135C563.455 73.2179 462.491 40.9158 381.5 40.9158C303.045 40.9158 233.425 61.0342 170.516 81.2714C163.593 83.4985 156.745 85.7293 149.972 87.9359C95.414 105.711 45.677 121.915 0 121.915V115.915C44.6541 115.915 93.4029 100.043 148.17 82.2102C154.913 80.0144 161.748 77.7889 168.678 75.5597C231.666 55.2971 302.046 34.9158 381.5 34.9158C463.438 34.9158 565.136 67.4545 667.461 100.194L671.727 101.558C775.81 134.855 880.318 167.915 966.5 167.915C1052.74 167.915 1168.11 138.352 1284.07 102.503C1334.26 86.9885 1384.6 70.2839 1432.65 54.3376C1495.36 33.5273 1554.17 14.0084 1603.69 0.111572L1605.31 5.88838C1555.84 19.7717 1497.32 39.1943 1434.8 59.9478C1386.75 75.8942 1336.34 92.6263 1285.85 108.235Z"
      fill={colors.purpleLight}
    />
  </svg>
)
export default ZigZagLine
